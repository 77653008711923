import React, { useState, useEffect } from "react";
import { Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import toast from "react-hot-toast";
import Moment from "moment";
import Select from "react-select";
// components
import Navbar from "../../Components/Navbar/Navbar";
import Table from "../../Components/TableTemplate/OneTable";
import DeleteModal from "../../Components/Modals/DeleteModal";
import {
  numberFormat,
  numberFormatInt,
  getType,
  toastStyle,
  TokenExpiry,
  selectDropdownStyle,
} from "../../Helpers/Utils/Common";

// css
import "../Purchases/PurchaseOrders/PurchaseOrders.css";
import AddPaymentModal from "./AddPaymentModal";
import CloseFSModal from "./CloseFSModal";
import {
  searchProjectInvoice,
  deleteProjectInvoice,
  sendToClientProjectInvoice,
} from "./../../Helpers/apiCalls/ProjectInvoice/ProjectInvoiceApi";
import { searchProject } from "./../../Helpers/apiCalls/Manage/Projects";
import { getAllCustomer } from "./../../Helpers/apiCalls/Manage/CustomerAPI";

/**
 *  Sales Invoice Register component
 */
export default function ProjectInvoice() {
  let navigate = useNavigate();
  const location = useLocation();
  const accountType = getType();
  const [inactive, setInactive] = useState(true);
  const [allData, setAllData] = useState([]);
  const [totalSummary, setTotalSummary] = useState({
    total: 0,
    total_paid_amount: 0,
    total_balance: 0,
  });

  const [projects, setProjects] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [projectInvoiceId, setProjectInvoiceId] = useState("");

  const [projectId, setProjectId] = useState("");
  const [bill, setBill] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [bal, setBal] = useState("");
  const [selectedRow, setSelectedRow] = useState({});

  /* Close bill Modal */
  const [showCloseBillModal, setShowCloseBillModal] = useState(false);
  const handleShowCloseBillModal = () => setShowCloseBillModal(true);
  const handleCloseCloseBillModal = () => setShowCloseBillModal(false);

  /* add payment modal handler */
  const [showAddPaymentModal, setShowAddPaymentModal] = useState(false);
  const handleShowAddPaymentModal = () => setShowAddPaymentModal(true);
  const handleCloseAddPaymentModal = () => {
    setShowAddPaymentModal(false);
  };

  /* delete modal handler */
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleShowDeleteModal = () => setShowDeleteModal(true);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  /* FILTER CONFIGS */
  const [filterConfig, setFilterConfig] = useState({
    status: "pending",
    franchisee_name: "",
    invoice_no: "",
    invoice_status: "",
    date_from: "",
    date_to: "",
    tabKey: "",
  });

  function handleFilterChange(e) {
    const { name, value } = e.target;
    setFilterConfig((prev) => {
      return { ...prev, [name]: value };
    });
  }

  async function handleSentToClient(id) {
    const response = await sendToClientProjectInvoice(id);
    if (response.data) {
      toast.success("Project Invoice Successfully Sent!", {
        style: toastStyle(),
      });
      fetchData();
      // setTimeout(() => refreshPage(), 1000);
    } else {
      toast.error("Error Deleting Project Invoice", {
        style: toastStyle(),
      });
    }
  }

  function handleSelectChange(e, id, project_id, balance ,row) {
    setBill(row);
    setSelectedRow(row);
    if (e.target.value === "edit-pi") {
      navigate("edit/" + id);
    } else if (e.target.value === "add-invoice-pi") {
      handleSentToClient(id);
    } else if (e.target.value === "view-invoice") {
      window.open("projectinvoice/print/" + id, "_blank");
    } else if (e.target.value === "process-req") {
      navigate("process/" + id);
    } else if (e.target.value === "print-pi") {
      window.open("projectinvoice/print/" + id, "_blank");
    } else if (e.target.value === "payment-pi") {
      setProjectInvoiceId(id);
      setProjectId(project_id);
      setBal(numberFormatInt(balance));
      handleShowAddPaymentModal();
    } else if (e.target.value === "delete-pi") {
      setProjectInvoiceId(id);
      handleShowDeleteModal();
    } else if (e.target.value === "close-pi") {
      handleShowCloseBillModal();
    }
  }

  const handleTabSelect = (tabKey) => {
    var newFilterConfig = {
      status: tabKey,
    };

    switch (tabKey) {
      case "pending":
        setAllData([]);
        newFilterConfig.status = "pending";
        newFilterConfig.payment_status = ""; // both open and closed bills
        setFilterConfig(() => {
          return newFilterConfig;
        });
        break;
      case "sent":
        setAllData([]);
        newFilterConfig.status = "sent";
        newFilterConfig.payment_status = ""; // both open and closed bills
        setFilterConfig(() => {
          return newFilterConfig;
        });
        break;
      case "open_bill":
        setAllData([]);
        newFilterConfig.status = "open_bill";
        newFilterConfig.payment_status = "";
        setFilterConfig(() => {
          return newFilterConfig;
        });
        break;
      case "closed_bill":
        const date = new Date();
        date.setDate(date.getDate() - 7);

        setAllData([]);
        newFilterConfig.status = "closed_bill";
        newFilterConfig.payment_status = "";
        newFilterConfig.date_from = new Date(date);
        newFilterConfig.date_to = new Date();
        setFilterConfig(() => {
          return newFilterConfig;
        });
        break;
      default:
        setAllData([]);
        break;
    }
  };

  function ActionBtn(row, type) {
    return (
      <Form.Select
        name="action"
        className="PO-select-action"
        onChange={(e) =>
          handleSelectChange(e, row.id, row.project_id, row.balance, row)
        }
    
        value={""}
      >
        <option value="" hidden selected>
          Select
        </option>
        {type === "pending" ||
        (type === "quotation" &&
          (accountType === "admin" || accountType === "franchise_officer")) ? (
          <option value="edit-pi" className="color-options">
            Edit
          </option>
        ) : null}

        {(type === "open" || type === "closed") && accountType === "admin" ? (
          <option value="edit-pi" className="color-options">
            Edit
          </option>
        ) : null}
        {type === "sent" ||
        type === "open" ||
        type === "pending" ||
        type === "closed" ? (
          <option value="print-pi" className="color-options">
            View
          </option>
        ) : null}
        {type === "sent" || type === "open" ? (
          <option value="payment-pi" className="color-options">
            Add Payment
          </option>
        ) : null}

        {type === "pending" ? (
          <option value="add-invoice-pi" className="color-options">
            Send
          </option>
        ) : null}

        {type === "refundable" ? (
          <option value="close-pi" className="color-options">
            Close
          </option>
        ) : null}

        {(accountType === "admin" || accountType === "franchise_officer") && (
          <option value="delete-pi" className="color-red">
            Delete
          </option>
        )}
      </Form.Select>
    );
  }

  function AddPaymentBtn(row) {
    if (row.status === "open_bill" || row.status === "sent") {
      return (
        <button
          name="action"
          className="btn btn-sm view-btn-table"
          id={row.id}
          onClick={(e) =>
            handleSelectChange(e, row.id, row.project_id, row.balance, row)
          }
          value="payment-pi"
        >
          Pay
        </button>
      );
    }
  }

  // function ViewBtn(row) {
  //   return (
  //     <button
  //       name="action"
  //       className="btn btn-sm view-btn-table"
  //       id={row.id}
  //       onClick={(e) =>
  //         handleSelectChange(e, row.id, row.project_id, row.balance)
  //       }
  //       value="view-invoice"
  //     >
  //       View
  //     </button>
  //   );
  // }

  // function ViewInvoice(row) {
  //   return (
  //     <button
  //       name="action"
  //       className="btn btn-sm view-btn-table"
  //       id={row.id}
  //       onClick={(e) => handleSelectChange(e, row.id)}
  //       value="print-pi"
  //     >
  //       View
  //     </button>
  //   );
  // }

  async function fetchData() {
    setShowLoader(true);
    setAllData([]);
    const response = await searchProjectInvoice(filterConfig);
    if (response.data) {
      if (response.data.summary) {
        setTotalSummary(response.data.summary);
      }

      var allBills = response.data.data.map((data, index) => {
        var franchise = data;
        var stat = data.payment_status.split("_");
        franchise.payment_status =
          stat.length < 2 ? data.payment_status : stat[0] + " " + stat[1];
        franchise.franchised_on =
          Moment(franchise.franchised_on).format("MM-DD-YYYY") || "N/A";
        franchise.grand_total = numberFormat(franchise.grand_total);
        franchise.paid_amount = numberFormat(franchise.paid_amount);
        franchise.project_amount = numberFormat(franchise.project_amount);
        franchise.subtotal = franchise.subtotal
          ? numberFormat(franchise.subtotal)
          : "";
        franchise.balance =
          data.payment_status !== "overpaid"
            ? numberFormat(franchise.balance)
            : numberFormat(franchise.balance.split("-")[1]);
        franchise.due_date =
          !franchise.due_date || franchise.due_date === "0000-00-00"
            ? null
            : Moment(franchise.due_date).format("YYYY-MM-DD");
        // Check for null, undefined, or invalid due_date
        if (!franchise.due_date || isNaN(new Date(franchise.due_date))) {
          franchise.overdue_days = ""; // Set to blank if the due_date is invalid
        } else {
          const currentDate = new Date();
          const dueDate = new Date(franchise.due_date);
          
          // Calculate overdue days from due_date to current date
          franchise.overdue_days = Math.ceil(
            (currentDate - dueDate) / (1000 * 60 * 60 * 24)
          );

          // If the due date is in the future, set overdue_days to 0
          if (franchise.overdue_days < 0) {
            franchise.overdue_days = 0;
          }
        }
        franchise.delivery_date = Moment(franchise.delivery_date).format(
          "MM-DD-YYYY"
        );
        franchise.sales_date = Moment(franchise.sales_date).format(
          "MM-DD-YYYY"
        );
        return franchise;
      });
      setAllData(allBills.reverse());
      
    } else if (response.error) {
      TokenExpiry(response);
      setAllData([]);
    }
    setShowLoader(false);
  }

  async function fetchProjects() {
    setShowLoader(true);
    setProjects([]);

    const response = await searchProject(filterConfig);
    if (response.error) {
      if (response.error.data.status !== 404) {
        TokenExpiry(response.error);
      }
    } else {
      var projects = response?.data?.data?.map((data) => {
        var info = {};
        info.label = data.name;
        info.value = data.id;
        return info;
      });
      setProjects([{ label: "All Projects", value: "" }, ...projects]);
    }
    setShowLoader(false);
  }

  async function fetchCustomers() {
    setShowLoader(true);
    setCustomers([]);

    const response = await getAllCustomer(filterConfig);
    if (response.error) {
      TokenExpiry(response.error);
    } else {
      var projects = response?.data?.data?.data?.map((data) => {
        var info = {};
        info.label = data.name;
        info.value = data.name;
        return info;
      }); 
      setCustomers([{ label: "All Customers", value: "" }, ...projects]);
    }
    setShowLoader(false);
  }

  async function handleDeletePI() {
    const response = await deleteProjectInvoice(projectInvoiceId);

    if (response.data.status === "success") {
      toast.success("Project Invoice Deleted Successfully!", {
        style: toastStyle(),
      });
      // setTimeout(() => refreshPage(), 1000);
      setShowDeleteModal(false);
      fetchData();
    } else {
      toast.error("Error Deleting Project Invoice", {
        style: toastStyle(),
      });
    }
  }

  useEffect(() => {
    fetchProjects();
    fetchCustomers();

    const invoiceLocation = location.state ? location.state.name : "pending";

    if (invoiceLocation === "sent") {
      var newFilterConfig = {
        status: "sent",
      };
      newFilterConfig.status = "sent";
      setFilterConfig(() => {
        return newFilterConfig;
      });
    }
  }, [location]);

  useEffect(() => {
    fetchData();
  }, [filterConfig]);

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive);
          }}
          active={"SALES"}
        />
      </div>

      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
        {/* headers */}
        <Row className="mb-4">
          <Col xs={6}>
            <h1 className="page-title">PROJECT INVOICE</h1>
          </Col>
          <Col xs={6} className="d-flex justify-content-end">
            <input
              type="search"
              name="anything"
              placeholder="Search..."
              value={filterConfig.anything}
              onChange={(e) => handleFilterChange(e)}
              className="search-bar"
            />
            <button
              className="add-btn"
              onClick={() => navigate("/projectinvoice/add")}
            >
              Add
            </button>
          </Col>
        </Row>

        <Tabs
          activeKey={filterConfig.status}
          defaultActiveKey={filterConfig.status}
          id="PO-tabs"
          onSelect={handleTabSelect}
        >
          <Tab eventKey="pending" title="PENDING INVOICE">
            {/* filters */}
            <div className="my-2 ms-2 PO-filters PI-filters d-flex">
              <span className="me-3 align-middle mt-2">Filter By:</span>
              <Select
                className="dropsearch-filter px-0 py-0 me-2"
                classNamePrefix="react-select"
                placeholder="Select Project"
                styles={selectDropdownStyle}
                options={projects}
                onChange={(e) => {
                  setFilterConfig((prev) => {
                    return { ...prev, project_id: e.value };
                  });
                }}
              />
              <Select
                className="dropsearch-filter px-0 py-0 me-2"
                classNamePrefix="react-select"
                placeholder="Select Customer"
                styles={selectDropdownStyle}
                options={customers}
                onChange={(e) => {
                  setFilterConfig((prev) => {
                    return { ...prev, company: e.value };
                  });
                }}
              />
            </div>

            {/* content */}
            <div className="sales-tbl">
              <Table
                tableHeaders={[
                  "-",
                  "INV DATE",
                  "PROJECT DATE",
                  "PROJ",
                  "COMPANY",
                  "DUE DATE",
                  "INV AMOUNT",
                  "PAID AMOUNT",
                  "ACTIONS",
                ]}
                headerSelector={[
                  "-",
                  "invoice_date",
                  "project_date",
                  "project_name",
                  "company",
                  "due_date",
                  "grand_total",
                  "paid_amount",
                ]}
                tableData={allData}
                ActionBtn={(row) => ActionBtn(row, "pending")}
                // ViewBtn={(row) => ViewBtn(row)}
                showLoader={showLoader}
              />
            </div>
            <div className="mb-2" />
          </Tab>
          {/* <Tab eventKey="sent" title="SENT INVOICE">
            <div className="my-2 ms-2 PO-filters PI-filters d-flex">
              <span className="me-3 align-middle mt-2">Filter By:</span>
              <Select
                className="dropsearch-filter px-0 py-0 me-2"
                classNamePrefix="react-select"
                placeholder="Select Project"
                styles={selectDropdownStyle}
                options={projects}
                onChange={(e) => {
                  setFilterConfig((prev) => {
                    return { ...prev, project_id: e.value };
                  });
                }}
              />
              <Select
                className="dropsearch-filter px-0 py-0 me-2"
                classNamePrefix="react-select"
                placeholder="Select Customer"
                styles={selectDropdownStyle}
                options={customers}
                onChange={(e) => {
                  setFilterConfig((prev) => {
                    return { ...prev, company: e.value };
                  });
                }}
              />
            </div>
            <div className="sales-tbl">
              <Table
                tableHeaders={[
                  "INV DATE",
                  "PROJECT DATE",
                  "PROJ",
                  "COMPANY",
                  "DUE DATE",
                  "INV AMOUNT",
                  "PAID AMOUNT",
                  "ACTIONS",
                ]}
                headerSelector={[
                  "invoice_date",
                  "project_date",
                  "project_name",
                  "company",
                  "due_date",
                  "grand_total",
                  "paid_amount",
                ]}
                tableData={allData}
                ActionBtn={(row) => ActionBtn(row, "sent")}
                showLoader={showLoader}
              />
            </div>
            <div className="mb-2" />
          </Tab> */}

          <Tab eventKey="open_bill" title="Open Invoice">
            {/* filters */}
            <div className="my-2 ms-2 PO-filters PI-filters d-flex">
              <span className="me-3 align-middle mt-2">Filter By:</span>
              <Select
                className="dropsearch-filter px-0 py-0 me-2"
                classNamePrefix="react-select"
                placeholder="Select Project"
                styles={selectDropdownStyle}
                options={projects}
                onChange={(e) => {
                  setFilterConfig((prev) => {
                    return { ...prev, project_id: e.value };
                  });
                }}
              />
              <span
                className="me-4 ml-4 align-middle mt-2 ps-label"
                style={{ fontWeight: "bold" }}
              >
                Total:{" "}
                {totalSummary.total ? numberFormat(totalSummary.total) : "0.00"}
              </span>

              <span
                className="me-4 ml-8 align-middle mt-2 ps-label"
                style={{ fontWeight: "bold" }}
              >
                Total Paid:{" "}
                {totalSummary.total_paid_amount
                  ? numberFormat(totalSummary.total_paid_amount)
                  : "0.00"}
              </span>

              <span
                className="me-4 ml-8 align-middle mt-2 ps-label"
                style={{ fontWeight: "bold" }}
              >
                Total Balance:{" "}
                {totalSummary.total_balance
                  ? numberFormat(totalSummary.total_balance)
                  : "0.00"}
              </span>
            </div>

            {/* content */}
            <div className="sales-tbl">
              <Table
                tableHeaders={[
                  "INV NO.",
                  "PROJ",
                  "COMPANY",
                  "INVOICE DATE",
                  "DUE DATE",
                  "OVERDUE DAY/S",
                  "INV AMOUNT",
                  "PAID AMOUNT",
                  "BALANCE",
                  "ACTIONS",
                ]}
                headerSelector={[
                  "id",
                  "project_name",
                  "company",
                  "invoice_date",
                  "due_date",
                  "overdue_days",
                  "grand_total",
                  "paid_amount",
                  "balance",
                ]}
                tableData={allData}
                ActionBtn={(row) => ActionBtn(row, "open")}
                showLoader={showLoader}
              />
            </div>
            <div className="mb-2" />
          </Tab>

          <Tab eventKey="closed_bill" title="Closed Invoice">
            <div className="my-2 ms-2 PO-filters PI-filters d-flex">
              <span className="me-3 align-middle mt-2">Filter By:</span>
              <Select
                className="dropsearch-filter px-0 py-0 me-2"
                classNamePrefix="react-select"
                placeholder="Select Project"
                styles={selectDropdownStyle}
                options={projects}
                onChange={(e) => {
                  setFilterConfig((prev) => {
                    return { ...prev, project_id: e.value };
                  });
                }}
              />

              <span className="me-3 align-middle mt-2">Date From:</span>
              <DatePicker
                name="date_from"
                placeholderText={"Select Date"}
                selected={filterConfig.date_from}
                onChange={(date) => {
                  setFilterConfig((prev) => {
                    return { ...prev, date_from: date };
                  });
                }}
                fixedHeight
                className="PI-date-btn me-3 form-control"
                showYearDropdown
                dateFormatCalendar="MMMM"
                yearDropdownItemNumber={20}
                scrollableYearDropdown
              />

              <span className="me-3 align-middle mt-2">To:</span>
              <DatePicker
                name="date_to"
                placeholderText={"Select Date"}
                selected={filterConfig.date_to}
                onChange={(date) => {
                  setFilterConfig((prev) => {
                    return { ...prev, date_to: date };
                  });
                }}
                className="PI-date-btn me-3 form-control"
                showYearDropdown
                dateFormatCalendar="MMMM"
                yearDropdownItemNumber={20}
                scrollableYearDropdown
              />
            </div>

            {/* content */}
            <div className="sales-tbl">
              <Table
                tableHeaders={[
                  "INV NO.",
                  "PROJ",
                  "COMPANY",
                  "DUE DATE",
                  "INV AMOUNT",
                  "PAID AMOUNT",
                  "BALANCE",
                  "ACTIONS",
                ]}
                headerSelector={[
                  "id",
                  "project_name",
                  "company",
                  "due_date",
                  "grand_total",
                  "paid_amount",
                  "balance",
                ]}
                tableData={allData}
                ActionBtn={(row) => ActionBtn(row, "closed")}
                // ViewBtn={(row) => ViewInvoice(row)}
                showLoader={showLoader}
              />
            </div>
            <div className="mb-2" />
          </Tab>
        </Tabs>
      </div>
      <DeleteModal
        show={showDeleteModal}
        onHide={() => handleCloseDeleteModal()}
        text="project sales invoice"
        onDelete={() => handleDeletePI()}
      />
      <AddPaymentModal
        id={projectInvoiceId}
        projectId={projectId}
        show={showAddPaymentModal}
        onHide={handleCloseAddPaymentModal}
        balance={bal}
        invoice={selectedRow}
      />
      <CloseFSModal
        show={showCloseBillModal}
        hide={handleCloseCloseBillModal}
        type="close"
        selectedRow={bill}
        page={"view"}
      />
    </div>
  );
}
